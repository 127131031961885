import React from 'react';

// UI
import { PrimaryLink } from 'ui/Button';
// Components
import Seo from 'components/Seo';
import Header from 'components/Header/LoginHeader';

function NotFoundPage() {
  return (
    <>
      <Header />
      <Seo title="404: Not found" />
      <main>
        <div className="mt-20 font-poppins">
          <h1 className="h1-desktop mb-6 text-center font-semibold">404</h1>
          <p className="mb-16 text-center text-[28px]">Page not found</p>
          <div className="text-center">
            <PrimaryLink to="/" text="Back to home" />
          </div>
        </div>
      </main>
    </>
  );
}

export default NotFoundPage;
