import React from 'react';
import { Link } from 'gatsby';

// Assets
import { BrandLogo } from '../UI/Svgs';

function Header() {
  return (
    <header className="border-b-[1px] border-gray-02 py-5 md:py-8">
      <div className="flex items-center justify-between px-4 transition-all duration-200 md:px-8 lg:px-16">
        <Link to="/">
          <BrandLogo className="h-[17px] w-[150px] md:h-[30px] md:w-[260px]" />
        </Link>
      </div>
    </header>
  );
}

export default Header;
